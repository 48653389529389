import { lazy } from 'react';

import {
  ADMINISTRASJON_INKLUSJON_KANDIDAT_SEARCH_PATH,
  ADMINISTRASJON_KOLOSKOPIRAPPORT_PATH,
  ADMINISTRASJON_PERSONTJENESTEN_SEARCH_PATH, ADMINISTRASJON_PERSONTJENESTEN_UPDATE_PATH,
} from './routeConstants';

const AuthRoute = lazy(() => import('./AuthRoute'));
const AdministrasjonOversiktPage = lazy(
  () => import('features/administrasjon/AdministrasjonOversiktPage')
);

const SlettKoloskopirapportPage = lazy(
  () =>
    import('features/administrasjon/koloskopirapport/SlettKoloskopirapportPage')
);
const PersontjenestenSearchPage = lazy(
  () =>
    import(
      'features/administrasjon/persontjenestenSearch/PersontjenestenSearchPage'
    )
);

const PersontjenestenUpdatePage = lazy(
  () =>
    import(
      'features/administrasjon/persontjenestenUpdate/PersontjenestenUpdatePage'
    )
);

const InklusjonKandidatSearchPage = lazy(
  () =>
    import(
      'features/administrasjon/inklusjonKandidatSearch/InklusjonKandidatSearchPage'
    )
);

export const administrasjonRoutes = [
  {
    index: true,
    element: (
      <AuthRoute>
        <AdministrasjonOversiktPage />
      </AuthRoute>
    ),
  },
  {
    path: ADMINISTRASJON_KOLOSKOPIRAPPORT_PATH,
    element: (
      <AuthRoute>
        <SlettKoloskopirapportPage />
      </AuthRoute>
    ),
  },
  {
    path: ADMINISTRASJON_PERSONTJENESTEN_SEARCH_PATH,
    element: (
      <AuthRoute>
        <PersontjenestenSearchPage />
      </AuthRoute>
    ),
  },
  {
    path: ADMINISTRASJON_PERSONTJENESTEN_UPDATE_PATH,
    element: (
      <AuthRoute>
        <PersontjenestenUpdatePage />
      </AuthRoute>
    ),
  },
  {
    path: ADMINISTRASJON_INKLUSJON_KANDIDAT_SEARCH_PATH,
    element: (
      <AuthRoute>
        <InklusjonKandidatSearchPage />
      </AuthRoute>
    ),
  },
];
